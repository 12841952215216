<template>
  <div class="relative mt-2 bg-white rounded shadow">
    <section v-if="!isLoaded" class="h-96">
      <loading :active="!isLoaded" :is-full-page="false" />
    </section>

    <section class="w-full bg-white" v-else>
      <TimelineGroup :logs="vehicles" timeline-type="vehicle" />
      <infinite-loading @infinite="fetchVehicleLogs" />
    </section>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { useEndpoints } from '@/composables'
import InfiniteLoading from 'vue-infinite-loading'
import TimelineGroup from '@/components/timeline-new/TimelineGroup.vue'

export default {
  name: 'ViewVehicleLogs',
  components: {
    InfiniteLoading,
    TimelineGroup,
  },
  computed: {
    actorIdentifier() {
      return this.vehicles.length
        ? this.getVehicleActorIdentifier(this.vehicles[0])
        : '--'
    },
    vehicleId() {
      return this.$route.params.id
    },
    logOffset() {
      return this.vehicles.length
    },
    logPath() {
      return (
        useEndpoints.vehicle.logs(this.vehicleId) +
        `?limit=${this.logLimit}&offset=${this.logOffset}`
      )
    },
  },
  async created() {
    await this.fetchVehicleLogs()
  },

  data() {
    return {
      headerTitle: 'header-title',
      vehicles: [],
      isLoaded: false,
      logLimit: 30,
      statuses: {
        BIKE_RESERVED: 'Requested for reservation',
        BIKE_RESERVATION_CANCELLED: 'Reservation cancelled',
        BIKE_TRIP_STARTED: 'Trip started',
        BIKE_TRIP_COMPLETED: 'Trip Completed',
        BIKE_TRIP_CANCELLED: 'Force Trip Cancelled',
        BIKE_UNLOCK_REQUEST: 'Unlock Request Sent',
        BIKE_CABLE_UNLOCK_REQUEST: 'Scooter Cable unlock request sent',
        BIKE_UNLOCKED: 'Vehicle Unlocked',
        BIKE_CABLE_UNLOCKED: 'Scooter Cable unlocked',
        BIKE_LOCK_REQUEST: 'Requested for locking vehicle',
        BIKE_CABLE_LOCK_REQUEST: 'Scooter Cable lock request sent',
        BIKE_LOCKED: 'Vehicle Locked',
        BIKE_CABLE_LOCKED: 'Scooter Cable locked',
        BIKE_GOOD_FEEDBACK: 'Rider Gave ‘Good’ Feedback',
        BIKE_BAD_FEEDBACK: 'Rider Gave ‘Bad’ Feedback',
        BIKE_FORCE_LOCK_REQUEST: 'Force Lock Request',
        BIKE_FORCE_UNLOCK_REQUEST: 'Force Unlock Request',
        BIKE_FORCE_TRIP_COMPLETED: 'Force Trip Completed',
        BIKE_INACTIVE: 'Vehicle is Inactive',
        BIKE_ACTIVE: 'Vehicle is Active',
        BIKE_ILLEGALLY_PARKED: 'Vehicle is Illegally Parked',
        BIKE_ILLEGALLY_MOVED: 'Vehicle is Illegally Moved',
        BIKE_OUT_OF_GEOFENCE: 'Vehicle is Out of Service Area',
        BIKE_CHARGER_CONNECTED: 'Vehicle is Charging',
        BIKE_CHARGER_DISCONNECTED: "Vehicle's Charger is Disconnected",
      },
      failedStatuses: {
        BIKE_RESERVED: 'Requested for reservation failed',
        BIKE_RESERVATION_CANCELLED: 'Reservation cancelletion failed',
        BIKE_TRIP_STARTED: 'Trip Start Failed',
        BIKE_TRIP_COMPLETED: 'Trip Complete Failed',
        BIKE_TRIP_CANCELLED: 'Force Trip Cancel Failed',
        BIKE_UNLOCK_REQUEST: 'Unlock Request Failed',
        BIKE_CABLE_UNLOCK_REQUEST: 'Scooter Cable unlock request failed',
        BIKE_UNLOCKED: 'Vehicle failed to Unlock',
        BIKE_CABLE_UNLOCKED: 'Scooter Cable unlocking failed',
        BIKE_LOCK_REQUEST: 'Lock Request Failed',
        BIKE_CABLE_LOCK_REQUEST: 'Scooter Cable lock request failed',
        BIKE_LOCKED: 'Vehicle failed to Lock',
        BIKE_CABLE_LOCKED: 'Scooter Cable lock failed',
        BIKE_GOOD_FEEDBACK: 'Error while giving feedback',
        BIKE_BAD_FEEDBACK: 'Error while giving feedback',
        BIKE_FORCE_LOCK_REQUEST: 'Force Lock Request Failed',
        BIKE_FORCE_UNLOCK_REQUEST: 'Force Unlock Request Failed',
        BIKE_FORCE_TRIP_COMPLETED: 'Force Trip Complete Failed',
        BIKE_INACTIVE: 'Failed: Vehicle set Inactive',
        BIKE_ACTIVE: 'Failed: Vehicle set Active',
        BIKE_ILLEGALLY_PARKED: 'Failed: Vehicle set Illegally Parked',
        BIKE_ILLEGALLY_MOVED: 'Failed: Vehicle set Illegally Moved',
        BIKE_OUT_OF_GEOFENCE: 'Failed: Vehicle set Out of Service Area',
        BIKE_CHARGER_CONNECTED: 'Failed: Vehicle set Charging',
        BIKE_CHARGER_DISCONNECTED: "Failed: Vehicle's Charger set Disconnected",
      },
      icons: {
        BIKE_RESERVED: require('@/assets/img/icons/alarm-clock.png'),
        BIKE_RESERVATION_CANCELLED: require('@/assets/icon/reserve_cancel.svg'),
        BIKE_TRIP_STARTED: require('@/assets/icon/start-icon.svg'),
        BIKE_TRIP_COMPLETED: require('@/assets/icon/finish-icon.svg'),
        BIKE_TRIP_CANCELLED: require('@/assets/icon/force_cancel.svg'),
        BIKE_UNLOCK_REQUEST: require('@/assets/icon/unlock_request.svg'),
        BIKE_UNLOCKED: require('@/assets/img/icons/unlocked.png'),
        BIKE_LOCK_REQUEST: require('@/assets/icon/lock_request.svg'),
        BIKE_LOCKED: require('@/assets/img/icons/lock.png'),
        BIKE_GOOD_FEEDBACK: require('@/assets/icon/good_feedback.svg'),
        BIKE_BAD_FEEDBACK: require('@/assets/icon/bad_feedback.svg'),
        BIKE_CABLE_UNLOCK_REQUEST: require('@/assets/icon/unlock_request.svg'),
        BIKE_CABLE_UNLOCKED: require('@/assets/icon/cable_unlocked.svg'),
        BIKE_CABLE_LOCK_REQUEST: require('@/assets/icon/lock_request.svg'),
        BIKE_CABLE_LOCKED: require('@/assets/icon/cable_locked.svg'),
        BIKE_FORCE_LOCK_REQUEST: require('@/assets/icon/force_lock.svg'),
        BIKE_FORCE_UNLOCK_REQUEST: require('@/assets/icon/force_unlock.svg'),
        BIKE_FORCE_TRIP_COMPLETED: require('@/assets/icon/force_complete.svg'),
        BIKE_INACTIVE: require('@/assets/icon/status/vehicle-inactive.svg'),
        BIKE_ACTIVE: require('@/assets/icon/status/vehicle-active.svg'),
        BIKE_ILLEGALLY_PARKED: require('@/assets/icon/status/illigally-parked.svg'),
        BIKE_ILLEGALLY_MOVED: require('@/assets/icon/status/illigally-moved.svg'),
        BIKE_OUT_OF_GEOFENCE: require('@/assets/icon/bell-icon.svg'),
        BIKE_CHARGER_CONNECTED: require('@/assets/icon/status/charger-connected.svg'),
        BIKE_CHARGER_DISCONNECTED: require('@/assets/icon/status/charger-disconnected.svg'),
      },
    }
  },

  methods: {
    async fetchVehicleLogs($state = null) {
      await this.$http
        .get(this.logPath)
        .then((res) => {
          console.log('res = ', res.data)
          if (this.vehicles.length) {
            this.vehicles = this.vehicles.concat(res.data['data'])
            console.log('Loaded later', this.vehicles.length)
          } else {
            this.vehicles = res.data['data']
            console.log('Loaded first', res.data['data'].length)
            if (!this.isLoaded) this.isLoaded = true
          }
          if ($state) {
            if (res.data['meta']['count']['total'] > this.vehicles.length) {
              $state.loaded()
            } else {
              $state.complete()
            }
          }
        })
        .catch((err) => {
          console.log('err = ', err.response.data)
          this.isLoaded = true
        })
    },
    getVehicleActorIdentifier(data) {
      return data.actor && data.actor.bike_short_id && data.actor.qr_code
        ? `${data.actor.bike_short_id} (${data.actor.qr_code})`
        : '--'
    },
  },
}
</script>

<style lang="scss"></style>
